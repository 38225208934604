/**
 * Creates cookie for login component in AEM and
 *  loading before customer api to be able to connect with test envs
 */
function createCustomerEnvCookie() {
    const AemDomainsList = /^(?:dev|dev2|stage)(?:-(.*))(?:.accor)\.(?:com)$/gm;
    const AemDomainExec = AemDomainsList.exec(window.location.host) || null;
    const valueset = document.querySelector('[data-customer-env]')?.getAttribute('data-customer-env');
    if (valueset && AemDomainExec) {
        const name = 'customer-env';
        const value = valueset;
        document.cookie = name + "=" + value + "" + "; domain=" + window.location.host + "; path=/";
    }
}

createCustomerEnvCookie();
