// Stylesheets
import './theme.scss';
import './resources/images/favicon-16x16.png';
import './resources/images/favicon-32x32.png';
import './resources/images/apple-touch-icon.png';
import './resources/images/android-chrome-192x192.png';
import './resources/images/android-chrome-512x512.png';
import './resources/images/mstile-150x150.png';
//components 
import './components/common/country-flags/v1/js/country-flags.js';
import './components/common/login-connect/customer-env.js';
// Tracking
import '@accor/tracking/src/index';
// ACE Library JS
import './site/lib/lib-initializer.js';

document.dispatchEvent(new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT));
