/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/**
 * @author Samuvel Jayaprakash
 * @fileOverview
 */
import TagManager from '@accor/core-services/dist/gtm/index';

export default class GeoLocationSelector extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-geolocation-selector';
  static GEOLOC_SESSION_NAME = 'POS.geolocDisplay';
  static GEOLOC_ZONE_COOKIE_NAME = 'displayZone';
  static GEOLOC_CZONE_COOKIE_NAME = 'contribZone';
  static GEOLOC_LANG_COOKIE_NAME = 'userLang';
  static GEOLOC_DEFAULT_COUNTRY = 'united-kingdom';
  static GEOLOC_DEFAULT_LANG = 'en';
  static GEOLOC_COOKIE_DOMAIN = '.accor.com';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.geoLocationSelectorHooks();
    this.geoLocationSelectorBindEvents();
    this.geoLocationSelectorApiHandler(CoreJS.DomEventConstants.ON_LOAD);
  }

  geoLocationSelectorHooks() {
    this.languageButton = this.componentHost.querySelector(`.${GeoLocationSelector.CLASS_NAMESPACE}__button`);
    this.languageModal = this.componentHost.querySelector(`.${GeoLocationSelector.CLASS_NAMESPACE}__modal`);
    this.redirectButton = this.languageModal?.querySelector('.geolocdropdown__btn');
    GeoLocationSelector.languageButtonLabel = this.languageButton?.querySelector(`.${GeoLocationSelector.CLASS_NAMESPACE}__label-value`);
    GeoLocationSelector.geoLocFirstSSD = this.languageModal?.querySelector('.geoloc-first-ssd');
    GeoLocationSelector.geoLocSecondSSD = this.languageModal?.querySelector('.geoloc-second-ssd');
  }

  geoLocationSelectorBindEvents() {
    this.redirectButton?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.geoLocationSelectorApiHandler(CoreJS.DomEventConstants.CLICK);
    });
  }

  geoLocationTracking(datasEvent) {
    TagManager.trackEvent(datasEvent);
  }

  geoLocationSelectorApiHandler(event) {
    const { siteCode, apiUrlManual, apiUrlPosCountries, key } = JSON.parse(
      document.body?.dataset?.posHandler
    );
    this.sessionExist = GeoLocationSelector.getGeolocSession(GeoLocationSelector.GEOLOC_SESSION_NAME);
    if (
      siteCode &&
            apiUrlPosCountries &&
            key &&
            !this.sessionExist &&
            event !== CoreJS.DomEventConstants.CLICK
    ) {
      const paramsObj = { 'siteCode': siteCode };
      const apiUrlParams = new URLSearchParams(paramsObj);
      const apiUrlManualFinal = `${apiUrlPosCountries}?${apiUrlParams.toString()}`;
      new CoreJS.XHRPromisifiedRequest(
        apiUrlManualFinal,
        CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET
      )
        .setRequestHeaders({ apikey: key })
        .executeRequest()
      // Do nothing, but xhr call has worked
        .then(this.geoLocationResponseStorage)
        .catch((err) => console.error('Augh, there was an error!', err));
    } else if (
      siteCode &&
            apiUrlManual &&
            key &&
            event === CoreJS.DomEventConstants.CLICK
    ) {
      this.secondDropSelectedValue = GeoLocationSelector.geoLocSecondSSD?.options[GeoLocationSelector.geoLocSecondSSD.selectedIndex];
      const { country, lang } = this.secondDropSelectedValue?.dataset;
      const datasEvent = {
        event: 'GAevent',
        eventAction: 'Menu - Select country - Click on CTA',
        eventCategory: 'Navigation',
        eventLabel: `Validate - ${country} - ${lang}`
      };
      this.geoLocationTracking(datasEvent);
      const paramsObj = { 'accorCountryCode': country, 'languageCode': lang, 'siteCode': siteCode };
      const apiUrlParams = new URLSearchParams(paramsObj);
      const apiUrlManualFinal = `${apiUrlManual}?${apiUrlParams.toString()}`;
      const isThereAnAlternativePageInTheCorrectLanguage =
                document.querySelector(
                  `link[rel=alternate][hreflang=${lang}]`
                );

      function redirectToNextPage(cRes) {
        const cData = JSON.parse(cRes.responseText);
        const redirectedUrl = isThereAnAlternativePageInTheCorrectLanguage ?
          isThereAnAlternativePageInTheCorrectLanguage.href :
          cData.redirection.url;

        const cookieAccorDomain = '.accor.com';
        const today = new Date();
        today.setFullYear(today.getFullYear() + 1);
        CoreJS.CookieHelper.createMaxAgeCookie(GeoLocationSelector.GEOLOC_ZONE_COOKIE_NAME, cData.accorCountryCode, today.toGMTString(), CoreJS.CookieHelper.COOKIE_PATH_DEFAULT, CoreJS.CookieHelper.COOKIE_SECURE_DEFAULT, cookieAccorDomain);
        CoreJS.CookieHelper.createMaxAgeCookie(GeoLocationSelector.GEOLOC_LANG_COOKIE_NAME, cData.languageCode, today.toGMTString(), CoreJS.CookieHelper.COOKIE_PATH_DEFAULT, CoreJS.CookieHelper.COOKIE_SECURE_DEFAULT, cookieAccorDomain);
        CoreJS.CookieHelper.createMaxAgeCookie(GeoLocationSelector.GEOLOC_CZONE_COOKIE_NAME, cData.pointOfSaleCode, today.toGMTString(), CoreJS.CookieHelper.COOKIE_PATH_DEFAULT, CoreJS.CookieHelper.COOKIE_SECURE_DEFAULT, cookieAccorDomain);
        window.location.href = redirectedUrl;
      }

      new CoreJS.XHRPromisifiedRequest(
        apiUrlManualFinal,
        CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET
      )
        .setRequestHeaders({ apikey: key })
        .executeRequest()
      // Do nothing, but xhr call has worked
        .then(redirectToNextPage)
        .catch((err) => console.error('Augh, there was an error!', err));
    } else {
      GeoLocationSelector.geoLocationFirstDropHtml();
    }
  }

  geoLocationResponseStorage(res) {
    const data = JSON.parse(res.responseText);
    new CoreJS.SessionStorageMgt(GeoLocationSelector.GEOLOC_SESSION_NAME, data, 1440).sessionSet();
    GeoLocationSelector.geoLocationFirstDropHtml();
  }

  static getGeolocCookie(key) {
    return CoreJS.CookieHelper.getCookieValue(key);
  }

  static getGeolocSession(name) {
    return new CoreJS.SessionStorageMgt(name).sessionGet();
  }

  static geoLocationFirstDropHtml() {
    const sessionData = GeoLocationSelector.getGeolocSession(GeoLocationSelector.GEOLOC_SESSION_NAME);
    const displayZoneCookie = GeoLocationSelector.getGeolocCookie(GeoLocationSelector.GEOLOC_ZONE_COOKIE_NAME);
    if (sessionData) {
      let dynamicContinentOptionMarkup = '';
      let defaultSelectedContinent = false;
      for (const continent of sessionData) {
        for (const country of continent.countryList) {
          if (displayZoneCookie && country.accorCountryCode === displayZoneCookie) {
            defaultSelectedContinent = true;
          } else if (!displayZoneCookie &&
                        country.accorCountryCode === GeoLocationSelector.GEOLOC_DEFAULT_COUNTRY) {
            defaultSelectedContinent = true;
          }
        }
        this.selectedAttribute = defaultSelectedContinent ? 'selected' : '';
        dynamicContinentOptionMarkup += `<option ${this.selectedAttribute} data-id="${continent.continentLabel}" value="${continent.continentLabel}">
          ${continent.continentLabel}
        </option>`;
        defaultSelectedContinent = false;
      }
      GeoLocationSelector.geoLocFirstSSD?.insertAdjacentHTML('beforeend', dynamicContinentOptionMarkup);
      this.firstDropSelectedValue = GeoLocationSelector.geoLocFirstSSD?.options[GeoLocationSelector.geoLocFirstSSD.selectedIndex].value;
      GeoLocationSelector.geoLocationSecondDropHtml(this.firstDropSelectedValue, sessionData, displayZoneCookie, CoreJS.DomEventConstants.ON_LOAD);
      GeoLocationSelector.geoLocFirstSSD?.addEventListener(CoreJS.DomEventConstants.CHANGE, (event) => {
        this.firstDropChangeValue = event.target.options[event.target.selectedIndex].value;
        GeoLocationSelector.geoLocationSecondDropHtml(this.firstDropChangeValue, sessionData, displayZoneCookie, CoreJS.DomEventConstants.CHANGE);
      });
    }
  }

  static geoLocationSecondDropHtml(firstDropSelectedValue, sessionData, displayZoneCookie, event) {
    const userLangCookie = GeoLocationSelector.getGeolocCookie(GeoLocationSelector.GEOLOC_LANG_COOKIE_NAME);
    if (sessionData) {
      let dynamicCountryOptionMarkup = '';
      let defaultSelectedCountry = false;
      let countryMLabel = '';
      let languageMLabel = '';
      for (const continent of sessionData) {
        if (continent.continentLabel === firstDropSelectedValue) {
          for (const country of continent.countryList) {
            if (displayZoneCookie && userLangCookie &&
                            country.accorCountryCode === displayZoneCookie &&
                            country.languageCode === userLangCookie &&
                            event !== CoreJS.DomEventConstants.CHANGE) {
              defaultSelectedCountry = true;
              countryMLabel = country.accorCountryLabel;
              languageMLabel = country.languageLabel;
            } else if (!displayZoneCookie &&
                            !userLangCookie &&
                            country.accorCountryCode === GeoLocationSelector.GEOLOC_DEFAULT_COUNTRY &&
                            country.languageCode === GeoLocationSelector.GEOLOC_DEFAULT_LANG) {
              defaultSelectedCountry = true;
              countryMLabel = country.accorCountryLabel;
              languageMLabel = country.languageLabel;
            }
            this.selectedSecondAttribute = defaultSelectedCountry ? 'selected' : '';
            dynamicCountryOptionMarkup += `<option ${this.selectedSecondAttribute} data-continent="${continent.continentLabel}" data-contribzone="${country.pointOfSale}"
              data-country="${country.accorCountryCode}" data-lang-label="${country.languageLabel}" data-lang="${country.languageCode}" lang="${country.languageCode}"
              value="${country.languageCode}_${country.accorCountryCode}">
              ${country.accorCountryLanguageLabel}
            </option>`;
            defaultSelectedCountry = false;
          }
        }
      }
      GeoLocationSelector.clearCustomFieldMarkup(GeoLocationSelector.geoLocSecondSSD);
      GeoLocationSelector.geoLocSecondSSD?.insertAdjacentHTML('beforeend', dynamicCountryOptionMarkup);

      function handleTabletChange() {
        if (window.innerWidth < 1024) {
          if (languageMLabel && countryMLabel) {
            GeoLocationSelector.clearCustomFieldMarkup(GeoLocationSelector.languageButtonLabel);
            GeoLocationSelector.languageButtonLabel.innerHTML = `${languageMLabel} (${countryMLabel})`;
          }
        } else {
          GeoLocationSelector.clearCustomFieldMarkup(GeoLocationSelector.languageButtonLabel);
          if (userLangCookie) {
            GeoLocationSelector.languageButtonLabel.innerHTML = userLangCookie;
          } else {
            GeoLocationSelector.languageButtonLabel.innerHTML = GeoLocationSelector.GEOLOC_DEFAULT_LANG;
          }
        }
      }

      if (event !== CoreJS.DomEventConstants.CHANGE) {
        handleTabletChange();
        window.addEventListener(CoreJS.DomEventConstants.RESIZE, handleTabletChange);
      }
    }
  }

  static clearCustomFieldMarkup(elementID) {
    if (elementID) {
      elementID.innerHTML = '';
    }
  }
}
// Register the NavBar component with the BaseComponent
CoreJS.BaseComponent.registerComponent(GeoLocationSelector.CLASS_NAMESPACE, GeoLocationSelector);
