const accorSites = ['businesstravel', 'meetings', 'travelpros'];
const url = window.location.href;

export const getPageAttributes = () => {
  const body = document.getElementsByTagName('body')[0];
  const dataCategory = body.getAttribute('data-category');
  const dataPage = body.getAttribute('data-page');

  return {
    'dataCategory': dataCategory ? dataCategory : _getDataCategory(),
    'dataPage': dataCategory ? dataPage : _getDataPage(dataPage),
    'dataIndex': body.getAttribute('data-index'),
    'dataSite': _getSite(),
    'dataSiteType': _getSiteType()
  }
}

export const getPagename = () => {
  const pageAttributes = getPageAttributes();

  if (Object.keys(pageAttributes).length > 0) {
    const dataPage = pageAttributes.dataPage;
    const dataIndex = pageAttributes.dataIndex;

    return pageAttributes.dataCategory + (dataPage ? '::' + dataPage : '') + (dataIndex ? '::' + dataIndex : '');
  }

  return '';
}

const _getSite = () => {
  for (const site of accorSites) {
    if (url.includes(site)) {
      return site;
    }
  }

  return '';
}

const _getSiteType = () => {
  const currentSite = _getSite();

  for (const site of accorSites) {
    if (currentSite === site) {
      return 'b2b';
    }
  }

  return '';
}

const _getDataCategory = () => {
  return _getUrlData(2);
}

const _getDataPage = (dataPage) => {
  return _getUrlData(1);
}

const _getUrlData = (index) => {
  if (url.includes('.html')) {
    let urlPage = url.split('.html')[0].split('/');
    let urlPageLength = urlPage.length;

    if (urlPageLength > 0) {
      return urlPage[urlPageLength - index];
    }
  }

  else return '';
}
