import { trackingEvent } from "./common";

export const trackCta = (trackedCta, eventGA4) => {
  const blocName = trackedCta.getAttribute('data-name') ? trackedCta.getAttribute('data-name').toLowerCase() : null;
  const blocInteraction = trackedCta.getAttribute('data-interaction') ? trackedCta.getAttribute('data-interaction').toLowerCase() : null;
  const blocType = trackedCta.getAttribute('data-type') || null;

  // ENROLL
  if (blocType === 'enroll') {
    if (blocName && blocInteraction) {
      eventGA4.eventName = 'cta_enroll';
      eventGA4.event_data = {
        'enroll_context' : blocName,
        'enroll_type': blocInteraction
      };
    }
  }
  // FILTER
  else if (blocType === 'filter') {
    if (blocInteraction) {
      const filterParent = trackedCta.closest('[data-name]');

      if (filterParent) {
        const filterBlocName = filterParent.getAttribute('data-name');
        const active = trackedCta.getAttribute('active') !== null ? 'apply' : 'remove';

        eventGA4.eventName = active + '_filter';
        eventGA4.event_data = {
          'filter_name' : filterBlocName,
          'filter_label': blocInteraction
        };
      }
    }
  }
  // SORT
  else if (blocType === 'sort') {
    const value = trackedCta.value ? trackedCta.value : 'none';
    eventGA4.eventName = 'sort_results';
    eventGA4.event_data = {
      'sort_label' : value
    };
  }
  // OPTION
  else if (blocType === 'option') {
    const isChecked = trackedCta.type === 'checkbox' ? trackedCta.checked : false;
    const quantity = trackedCta.getAttribute('data-quantity') || '1';

    eventGA4.eventName = 'option_interact';
    eventGA4.event_data = {
      'option_action' : isChecked ? 'add' : 'delete',
      'option_name': blocName,
      'option_type': blocInteraction,
      'option_quantity': isChecked ? quantity : '0'
    };
  }
  // CTA
  else {
    if (blocName && blocInteraction) {
      eventGA4.eventName = 'bloc_interact';
      eventGA4.event_data = {
        'bloc_name' : blocName,
        'bloc_interaction': blocInteraction
      }

      if (blocType) {
        eventGA4.event_data.bloc_type = blocType;
      }
    }
  }

  trackingEvent.addPageName(eventGA4);
}
