import { getPageAttributes, getPagename } from './page-attributes';

export const trackPage = () => {
  const pageAttributes = getPageAttributes();

  if (Object.keys(pageAttributes).length > 0) {
    const site = pageAttributes.dataSite;
    const siteType = pageAttributes.dataSiteType;

    const pageName = siteType + '::' + site + '::' + getPagename();

    TagManager.trackPage(pageName, {
      category: siteType || '',
      sub_category_level1: site || '',
      sub_category_level2: pageAttributes.dataCategory || '',
      sub_category_level3: pageAttributes.dataPage || '',
      sub_category_level4: pageAttributes.dataIndex || '',
    });
  }
}