import TagManager from '@accor/core-services/dist/gtm'
import { trackPage } from './services/datalayer'
import { trackingEvent } from './services/events/common'

function initTracking() {
  TagManager.initDefaultAutoTracking();
  if (document.getElementsByTagName('body')[0]) {
    trackPage();
    trackingEvent.addCTAListener();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  initTracking();
})

if (window.__NUXT__) {
  initTracking();
  if (document.getElementsByTagName('body')[0]) {
    _getNodeChange(document.getElementsByTagName('body')[0]);
  }
}

function _getNodeChange(target) {
  const targetNode = target;
  const config = { attributes: true };

  const callback = (mutationList) => {
  for (const mutation of mutationList) {
    if (mutation.type === 'attributes') {
        if (mutation.attributeName === 'data-category' || mutation.attributeName === 'data-page' || mutation.attributeName === 'data-index') {
          trackPage();
        }
      }
    }
  };

  const observer = new MutationObserver(callback);

  observer.observe(targetNode, config);
}
